
































































































































































































import Vue from "vue";
import { Messages } from "@/models/enums/messages.enum";
import { ResponsePagination } from "@/models/interface/common.interface";
import moment from "moment";
import { changeCurrencytoNumeric, currencyFormat } from "@/validator/globalvalidator";
import { contactServices } from "@/services/contact.service";
import { RequestQueryParamsModel } from "@/models/interface/http.interface";
import { ContactData } from "@/models/interface/contact.interface";
import { debounceProcess } from "@/helpers/debounce";
import { assetsServices } from "@/services/assets.service";
import { DataListMasterAsset } from "@/models/interface/assets.interface";
import { logisticServices } from "@/services/logistic.service";
import { DataResponseGetListWorkOrder } from "@/models/interface/logistic.interface";
export default Vue.extend({
  data() {
    this.getListCustomer = debounceProcess(this.getListCustomer, 200)
    this.getUnitCode = debounceProcess(this.getUnitCode, 200)
    this.getListDocumentNumber = debounceProcess(this.getListDocumentNumber, 200)
    return {
      formatCurrencytoNumber: changeCurrencytoNumeric,
      form: this.$form.createForm(this, { name: "currency" }),
      isFormSubmitted: false as boolean,
      loadingDocumentNumber: false as boolean,
      page: this.page || (1 as number),
      limit: this.limit || (10 as number),
      totalData: 0 as number,
      dataList: [] as any[],
      dataCustomer: [] as ContactData[],
      dataUnitCode: [] as DataListMasterAsset[],
      dataDocumentNumber: [] as DataResponseGetListWorkOrder[],
      loadingTable: false as boolean,
      loadingCustomer: false as boolean,
      loadingUnitCode: false as boolean,
      formRules: {
        workOrderNumber: {
          label: "Work Order Number",
          name: "workOrderNumber",
          placeholder: "Type for more data",
          decorator: [
            "workOrderNumber",
            {
              rules: [
                {
                  required: false,
                  message: Messages.VALIDATION_REQUIRED_ERROR,
                },
              ],
            },
          ],
        },
        dateFrom: {
          label: "Date From",
          name: "dateFrom",
          placeholder: "Insert Date From",
          decorator: [
            "dateFrom",
            {
              rules: [
                {
                  required: true,
                  message: Messages.VALIDATION_REQUIRED_ERROR,
                },
              ],
            },
          ],
        },
        dateTo: {
          label: "Date To",
          name: "dateTo",
          placeholder: "Insert Date To",
          decorator: [
            "dateTo",
            {
              rules: [
                {
                  required: true,
                  message: Messages.VALIDATION_REQUIRED_ERROR,
                },
              ],
            },
          ],
        },
        customerName: {
          label: "Customer Name",
          name: "customerName",
          placeholder: "Insert Customer Name",
          decorator: [
            "customerName",
            {
              rules: [
                {
                  required: false,
                  message: Messages.VALIDATION_REQUIRED_ERROR,
                },
              ],
            },
          ],
        },
        unitCode: {
          label: "Unit Code",
          name: "unitCode",
          placeholder: "Type for more data",
          decorator: [
            "unitCode",
            {
              rules: [
                {
                  required: false,
                  message: Messages.VALIDATION_REQUIRED_ERROR,
                },
              ],
            },
          ],
        },
      },
      columnsTable: [
        {
          title: "Work Order Number",
          dataIndex: "workOrderNumber",
          key: "workOrderNumber",
          width: 210,
          scopedSlots: { customRender: "isNull" },
        },
        {
          title: "Date",
          dataIndex: "workOrderDate",
          key: "workOrderDate",
          width: 120,
          scopedSlots: { customRender: "isNull" },
        },
        {
          title: "Customer Name",
          dataIndex: "customerName",
          key: "customerName",
          width: 200,
          scopedSlots: { customRender: "isNull" },
        },
        {
          title: "Unit Code",
          dataIndex: "unitCode",
          key: "unitCode",
          width: 120,
          scopedSlots: { customRender: "isNull" },
        },
        {
          title: "Product Code",
          dataIndex: "productCode",
          key: "productCode",
          width: 150,
          scopedSlots: { customRender: "isNull" },
        },
        {
          title: "Product Name",
          dataIndex: "productName",
          key: "productName",
          width: 150,
          scopedSlots: { customRender: "isNull" },
        },
        {
          title: "Qty",
          dataIndex: "customProductQty",
          key: "customProductQty",
          width: 120,
          scopedSlots: { customRender: "isNull" },
        },
      ] as any,
    };
  },
  computed: {
    formItemLayout() {
      return {
        labelCol: { span: 8 },
        wrapperCol: { span: 14 },
      };
    },
  },
  methods: {
    moment,
    assignSearch (key, value, and): string {
      if (key === 'workOrderNumber' && value) return and + `workOrder.documentNumber~${value}`
      else if (key === 'dateFrom' && value) return and + `workOrder.workOrderDate>=${moment(value).set({hour: 0, minute: 0, second: 0}).utcOffset("+07").format()}`
      else if (key === 'dateTo' && value) return and + `workOrder.workOrderDate<=${moment(value).set({hour: 23, minute: 59, second: 59}).utcOffset("+07").format()}`
      else if (key === 'customerName' && value) return and + `workOrder.customer.secureId~${value}`
      else if (key === 'unitCode' && value) return and + `asset.unitCode~${value}`
      else return ''
    },
    dynamicSearch (res):string {
      let search = ''
      Object.keys(res).forEach((key) => {
        if(!search) {
          search = this.assignSearch(key, res[key], '')
        } else {
          search += this.assignSearch(key, res[key], '_AND_')
        }
      })
      return search
    },
    findItems(): void {
      this.form.validateFields((err,res) => {
        if(err) return;
        let params = {
          page: this.page - 1,
          limit: this.limit,
        } as RequestQueryParamsModel
        params.search = this.dynamicSearch(res)
        this.loadingTable = true
        this.isFormSubmitted = true
        logisticServices
          .getListWorkOrder(params)
          .then(response => {
            this.totalData = response.totalElements
            this.dataList = response.data.map((dataMap, index) => {
              dataMap.key = index
              dataMap.workOrderDate = moment(dataMap.workOrderDate).format('DD-MMM-yyyy')
              dataMap.customProductQty = currencyFormat(dataMap.productQty)
              return dataMap
            })
          })
          .finally(() => {
            this.loadingTable = false
            this.isFormSubmitted = false
          })
      })
    },
    clearItems(): void {
      console.log("a");
      this.form.resetFields()
    },
    download(): void {
      console.log("a");
    },
    print(): void {
      console.log("a");
    },
    getListDocumentNumber (valueSearch) {
      let params = {
        page: 0,
        limit: 10,
      } as RequestQueryParamsModel
      if(valueSearch) params.search = `workOrder.documentNumber~*${valueSearch}*`
      this.loadingDocumentNumber = true
      logisticServices
        .getListWorkOrder(params)
        .then(response => this.dataDocumentNumber = response.data)
        .finally(() => this.loadingDocumentNumber = false)
    },
    getUnitCode (valueSearch) {
      let params = {
        page: 0,
        limit: 10,
      } as RequestQueryParamsModel
      if(valueSearch) params.search = `unitCode~*${valueSearch}*`
      this.loadingUnitCode = true
      assetsServices
        .listMasterAsset(params)
        .then(data => this.dataUnitCode = data.data)
        .finally(() => this.loadingUnitCode = false)
    },
    getListCustomer (valueSearch) {
      let params:RequestQueryParamsModel = {
        limit: 10,
        page: 0,
        search: 'customer~true_AND_active~true'
      }
      if(valueSearch) params.search += `_AND_firstName~*${valueSearch}*_OR_lastName~*${valueSearch}*`
      this.loadingCustomer = true
      contactServices
        .listContactData(params)
        .then(response => this.dataCustomer = response.data)
        .finally(() => this.loadingCustomer = false)
    },
    responsePageSizeChange(response: ResponsePagination): void {
      this.limit = response.size
      this.page = 1;
      this.findItems()
    },
    responseCurrentPageChange(response: ResponsePagination): void {
      this.page = response.page
      this.findItems()
    },
    CreateNew(): void {
      this.$router.push("/logistic/workorder/create");
    },
    filterOption (input, option) {
      return (
        option.componentOptions.children[0].componentOptions.children[1].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
      );
    },
  },
  created(){
    this.getListCustomer('')
    this.getUnitCode('')
    this.getListDocumentNumber('')
  }
});
